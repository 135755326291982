import React from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as indicacaoActions from 'store/modules/indicacao/actions'
import { removeMaskCPF } from 'util/masks'
import useRedux from 'hooks/useRedux'

interface PropostaIndicacaoProps {
  isOpen: boolean
  toggle: () => void
  cpf: string
  mensagem: string
  matriculas: string[]
}

const PropostaIndicacao = ({
  isOpen,
  toggle,
  cpf,
  mensagem,
  matriculas
}: PropostaIndicacaoProps) => {
  const { dispatch } = useRedux()

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <SimpleTitleCard colorIcon="F7931E">
          <AiOutlineExclamationCircle className="icon" />
          Cliente não-elegível
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column gap-1 align-items-center">
          <p>
            {mensagem
              ? mensagem
              : 'Cliente não elegível por política de crédito - Empréstimo Consignado Itaú'}
          </p>
          <div className="d-flex flex-column gap-1 align-items-center">
            {matriculas && matriculas.length > 0 && <p>M{matriculas}</p>}
          </div>
          <CustomButton
            onClick={() => {
              dispatch(
                indicacaoActions.criarPropostaIndicacaoSaga(removeMaskCPF(cpf))
              )
              toggle()
            }}
          >
            CRIAR INDICAÇÃO
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PropostaIndicacao
