import {
  TabContato,
  TabHistorico,
  TabInformacoes,
  TabProduto
} from 'models/atendimento/types'
import {
  AnswersFormularioNecessidade,
  Convenio,
  Perfil,
  ProdutosSimulacao
} from 'models/types'

export enum TYPES {
  SET_LOADING_START_ATENDIMENTO = '@@atendimento/SET_LOADING_START_ATENDIMENTO',
  START_ATENDIMENTO = '@@atendimento/START_ATENDIMENTO',
  END_ATENDIMENTO = '@@atendimento/END_ATENDIMENTO',
  SET_MODAL_ATENDIMENTO_AGENDADO = '@@atendimento/SET_MODAL_ATENDIMENTO_AGENDADO',
  SET_INVALID_CADASTRO_CLIENTE_MODAL = '@@atendimento/SET_INVALID_CADASTRO_CLIENTE_MODAL',
  CLEAN_INVALID_CADASTRO_CLIENTE_MODAL = '@@atendimento/CLEAN_INVALID_CADASTRO_CLIENTE_MODAL',
  SET_INFOS_CADASTRO_CLIENTE_MODAL = '@@atendimento/SET_INFOS_CADASTRO_CLIENTE_MODAL',
  CLEAN_INFOS_CADASTRO_CLIENTE_MODAL = '@@atendimento/CLEAN_INFOS_CADASTRO_CLIENTE_MODAL',
  SET_CONVENIOS_MODAL = '@@atendimento/SET_CONVENIOS_MODAL',
  SET_PERFIS_MODAL = '@@atendimento/SET_PERFIS_MODAL',
  TOGGLE_CONFIRMACAO_CADASTRO = '@@atendimento/TOGGLE_CONFIRMACAO_CADASTRO',
  SET_ENRIQUECIDO = '@@atendimento/SET_ENRIQUECIDO',
  TOGGLE_ENRIQUECIMENTO = '@@atendimento/TOGGLE_ENRIQUECIMENTO',
  TOGGLE_CADASTRO_SIMPLIFICADO = '@@atendimento/TOGGLE_CADASTRO_SIMPLIFICADO',
  SET_CPF_ATENDIMENTO = '@@atendimento/SET_CPF_ATENDIMENTO',
  SET_PAGINATION_ATENDIMENTOS = '@@atendimento/SET_PAGINATION_ATENDIMENTOS',
  CLEAN_CPF_ATENDIMENTO = '@@atendimento/CLEAN_CPF_ATENDIMENTO',
  SET_TAB_CLIENTE_ACTIVE = '@@atendimento/SET_TAB_CLIENTE_ACTIVE',
  SET_TAB_ATENDIMENTO_ACTIVE = '@@atendimento/SET_TAB_ATENDIMENTO_ACTIVE',
  TOGGLE_DETALHAMENTO = '@@atendimento/TOGGLE_DETALHAMENTO',
  SET_INFOS_PRODUTO = '@@atendimento/SET_INFOS_PRODUTO',
  SET_LOADING_BENEFICIO_IN100 = '@@atendimento/SET_LOADING_BENEFICIO_IN100',
  SET_LOADING_SIMULACAO = '@@atendimento/SET_LOADING_SIMULACAO',
  SET_INFOS_CARTAO_CONSIG = '@@atendimento/SET_INFOS_CARTAO_CONSIG',
  SET_INFOS_CARTAO_BENEFICIO = '@@atendimento/SET_INFOS_CARTAO_BENEFICIO',
  SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO = '@@atendimento/SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO',
  SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO = '@@atendimento/SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO',
  SELECT_PORTABILIDADE_PRODUTO_PORTABILIDADE = '@@atendimento/SELECT_PORTABILIDADE_PRODUTO_PORTABILIDADE',
  SELECT_PORTABILIDADE_PRODUTO_ASSOCIADO = '@@atendimento/SELECT_PORTABILIDADE_PRODUTO_ASSOCIADO',
  SET_INVALID_CONTRATACAO = '@@atendimento/SET_INVALID_CONTRATACAO',
  SELECT_PRODUTO = '@@atendimento/SELECT_PRODUTO',
  SET_INFOS_CONTATO_FIELD = '@@atendimento/SET_INFOS_CONTATO_FIELD',
  SET_INFOS_CONTATO = '@@atendimento/SET_INFOS_CONTATO',
  SET_INFOS_CHAMADA_CONTATO = '@@atendimento/SET_INFOS_CHAMADA_CONTATO',
  SET_INFOS_CHAMADA_CONTATO_BY_CPF_DISPATCH = '@@atendimento/SET_INFOS_CHAMADA_CONTATO_BY_CPF_DISPATCH',
  SET_INFOS_CHAMADA_INVALID_CONTATO = '@@atendimento/SET_INFOS_CHAMADA_INVALID_CONTATO',
  CLEAN_INFOS_CHAMADA_INVALID_CONTATO = '@@atendimento/CLEAN_INFOS_CHAMADA_INVALID_CONTATO',
  SET_PRODUTOS_SELECIONADOS = '@@atendimento/SET_PRODUTOS_SELECIONADOS',
  SET_SOMATORIO_TOTAL_PRODUTOS = '@@atendimento/SET_SOMATORIO_TOTAL_PRODUTOS',
  SET_SOMATORIO_RESUMO = '@@atendimento/SET_SOMATORIO_RESUMO',
  SET_INFORMACOES_ACTIVE_TAB_BENEFICIO = '@@atendimento/SET_INFORMACOES_ACTIVE_TAB_BENEFICIO',
  SET_INFORMACOES = '@@atendimento/SET_INFORMACOES',
  CLEAN_INFORMACOES = '@@atendimento/CLEAN_INFORMACOES',
  SET_INFORMACOES_FIELD = '@@atendimento/SET_INFORMACOES_FIELD',
  SET_INVALID_INFORMACOES_FIELD = '@@atendimento/SET_INVALID_INFORMACOES_FIELD',
  SET_INVALID_INFORMACOES_FIELD_ARRAY = '@@atendimento/SET_INVALID_INFORMACOES_FIELD_ARRAY',
  ADICIONAR_PERFIL = '@@atendimento/ADICIONAR_PERFIL',
  REMOVER_PERFIL = '@@atendimento/REMOVER_PERFIL',
  CLEAN_INVALID_INFORMACOES_FIELD = '@@atendimento/CLEAN_INVALID_INFORMACOES_FIELD',
  SET_OPTIONS_SELECTS_INFORMACOES = '@@atendimento/SET_OPTIONS_SELECTS_INFORMACOES',
  SET_INFOS_HISTORICO = '@@atendimento/SET_INFOS_HISTORICO',
  SET_TABS_HISTORICO = '@@atendimento/SET_TABS_HISTORICO',
  SET_PAGINATION_CONTRATOS = '@@atendimento/SET_PAGINATION_CONTRATOS',
  TOGGLE_FOMULARIO_NECESSIDADE = '@@atendimento/TOGGLE_FOMULARIO_NECESSIDADE',
  SET_TAB_FORMULARIO = '@@atendimento/SET_TAB_FORMULARIO',
  SET_INFOS_FORMULARIO = '@@atendimento/SET_INFOS_FORMULARIO',
  CLEAN_INFOS_FORMULARIO = '@@atendimento/CLEAN_INFOS_FORMULARIO',
  SET_MODAL_AGREGACAO = '@@atendimento/SET_MODAL_AGREGACAO',
  CLEAN_MODAL_AGREGACAO = '@@atendimento/CLEAN_MODAL_AGREGACAO',
  SET_PLANO_SEGURO_PRESTAMISTA_SELECIONADO = '@@atendimento/SET_PLANO_SEGURO_PRESTAMISTA_SELECIONADO',
  SET_MODAL_COBERTURA_SEGURO_PRESTAMISTA = '@@atendimento/SET_MODAL_COBERTURA_SEGURO_PRESTAMISTA',
  CLEAN_MODAL_COBERTURA_SEGURO_PRESTAMISTA = '@@atendimento/CLEAN_MODAL_COBERTURA_SEGURO_PRESTAMISTA',
  SET_MODAL_SEGURO_SINDNAPI = '@@atendimento/SET_MODAL_SEGURO_SINDNAPI',
  CLEAN_MODAL_SEGURO_SINDNAPI = '@@atendimento/CLEAN_MODAL_SEGURO_SINDNAPI',
  CLEAN_INVALID_INFOS_CEP = '@@atendimento/CLEAN_INVALID_INFOS_CEP',
  SET_INTEGRACAO = '@@atendimento/SET_INTEGRACAO',
  SET_MENSAGEM_ERRO_INTEGRACAO = '@@atendimento/SET_MENSAGEM_ERRO_INTEGRACAO',
  SET_INFO_CONTATO_MODAL_SMS_FIELD_DISPATCH = '@@atendimento/SET_INFO_CONTATO_MODAL_SMS_FIELD_DISPATCH',

  CONSULTA_CONSENTIMENTO_ITAU_SAGA = '@@atendimento/CONSULTA_CONSENTIMENTO_ITAU_SAGA',
  ENRIQUECER_DADOS_SAGA = '@@atendimento/ENRIQUECER_DADOS_SAGA',
  VALIDAR_NOVO_ATENDIMENTO_SAGA = '@@atendimento/VALIDAR_NOVO_ATENDIMENTO_SAGA',
  GET_FORMULARIO_ATENDIMENTO_30_SAGA = '@@atendimento/GET_FORMULARIO_ATENDIMENTO_30_SAGA',
  SALVAR_FORMULARIO_ATENDIMENTO_30_SAGA = '@@atendimento/SALVAR_FORMULARIO_ATENDIMENTO_30_SAGA',
  START_ATENDIMENTO_SAGA = '@@atendimento/START_ATENDIMENTO_SAGA',
  FINALIZA_AGENDAMENTO_SAGA = '@@atendimento/FINALIZA_AGENDAMENTO_SAGA',
  GET_CONVENIOS_SAGA = '@@atendimento/GET_CONVENIOS_SAGA',
  GET_CONVENIOS_INFO_SAGA = '@@atendimento/GET_CONVENIOS_INFO_SAGA',
  GET_PERFIS_SAGA = '@@atendimento/GET_PERFIS_SAGA',
  GET_PROFISSOES_SAGA = '@@atendimento/GET_PROFISSOES_SAGA',
  SALVAR_CADASTRO_SIMPLIFICADO_SAGA = '@@atendimento/SALVAR_CADASTRO_SIMPLIFICADO_SAGA',
  GET_PERFIL_CLIENTE_SAGA = '@@atendimento/GET_PERFIL_CLIENTE_SAGA',
  DISCAR_CLIENTE_SAGA = '@@atendimento/DISCAR_CLIENTE_SAGA',
  GET_LISTA_ATENDIMENTOS_SAGA = '@@atendimento/GET_LISTA_ATENDIMENTOS_SAGA',
  GET_TABULACOES_SAGA = '@@atendimento/GET_TABULACOES_SAGA',
  GET_TIPOS_ATENDIMENTO_SAGA = '@@atendimento/GET_TIPOS_ATENDIMENTO_SAGA',
  TABULAR_ATENDIMENTO_SAGA = '@@atendimento/TABULAR_ATENDIMENTO_SAGA',
  TABULAR_ATENDIMENTO_DISPATCH_SAGA = '@@atendimento/TABULAR_ATENDIMENTO_DISPATCH_SAGA',
  GET_PROPOSTAS_CRM_SAGA = '@@atendimento/GET_PROPOSTAS_CRM_SAGA',
  SOLICITAR_SIMULACAO_FGTS_SAGA = '@@atendimento/SOLICITAR_SIMULACAO_FGTS_SAGA',
  GET_PARCELAS_FGTS_SAGA = '@@atendimento/GET_PARCELAS_FGTS_SAGA',
  RECALCULO_PROPOSTA_SAGA = '@@atendimento/RECALCULO_PROPOSTA_SAGA',
  RECALCULO_PROPOSTA_FGTS_SAGA = '@@atendimento/RECALCULO_PROPOSTA_FGTS_SAGA',
  GET_RESUMO_CONTRATACAO_SAGA = '@@atendimento/GET_RESUMO_CONTRATACAO_SAGA',
  GET_SOLICITACOES_IN100_SAGA = '@@atendimento/GET_SOLICITACOES_IN100_SAGA',
  GET_TELEFONES_SAGA = '@@atendimento/GET_TELEFONES_SAGA',
  INSERIR_SOLICITACOES_IN100_SAGA = '@@atendimento/INSERIR_SOLICITACOES_IN100_SAGA',
  VALIDA_STATUS_IN100_SAGA = '@@atendimento/VALIDA_STATUS_IN100_SAGA',
  VALIDACAO_IN100_SAGA = '@@atendimento/VALIDACAO_IN100_SAGA',
  CONSENTIMENTO_ITAU_SAGA = '@@atendimento/CONSENTIMENTO_ITAU_SAGA',
  GET_PRODUTOS_CONTRATADOS_SAGA = '@@atendimento/GET_PRODUTOS_CONTRATADOS_SAGA',
  VALIDACAO_CONTRATACAO_SAGA = '@@atendimento/VALIDACAO_CONTRATACAO_SAGA',
  FINALIZACAO_CONTRATACAO_SAGA = '@@atendimento/FINALIZACAO_CONTRATACAO_SAGA',
  GET_DADOS_ENDERECO_SAGA = '@@atendimento/GET_DADOS_ENDERECO_SAGA',
  GET_INFORMACOES_CLIENTE_SAGA = '@@atendimento/GET_INFORMACOES_CLIENTE_SAGA',
  GET_CIDADES_SAGA = '@@atendimento/GET_CIDADES_SAGA',
  GET_CIDADES_NASCIMENTO_SAGA = '@@atendimento/GET_CIDADES_NASCIMENTO_SAGA',
  GET_GENEROS_SAGA = '@@atendimento/GET_GENEROS_SAGA',
  GET_ORGAOS_EMISSORES_SAGA = '@@atendimento/GET_ORGAOS_EMISSORES_SAGA',
  GET_ESTADOS_SAGA = '@@atendimento/GET_ESTADOS_SAGA',
  GET_ESCOLARIDADES_SAGA = '@@atendimento/GET_ESCOLARIDADES_SAGA',
  GET_ESTADOS_CIVIS_SAGA = '@@atendimento/GET_ESTADOS_CIVIS_SAGA',
  GET_TIPOS_CONTA_SAGA = '@@atendimento/GET_TIPOS_CONTA_SAGA',
  GET_BANCOS_SAGA = '@@atendimento/GET_BANCOS_SAGA',
  SALVAR_INFORMACOES_CLIENTE_SAGA = '@@atendimento/SALVAR_INFORMACOES_CLIENTE_SAGA',
  GET_CONTRATOS_SAGA = '@@atendimento/GET_CONTRATOS_SAGA',
  GET_INFOS_CLIENTE_CONSIG_SAGA = '@@atendimento/GET_INFOS_CLIENTE_CONSIG_SAGA',
  GET_INFORMACOES_SIMPLIFICADAS_SAGA = '@@atendimento/GET_INFORMACOES_SIMPLIFICADAS_SAGA',
  ADICIONAR_NOVO_TELEFONE_SAGA = '@@atendimento/ADICIONAR_NOVO_TELEFONE_SAGA',
  GET_MATRICULAS_MODAL_INDICACAO_SAGA = '@@atendimento/GET_MATRICULAS_MODAL_INDICACAO_SAGA',
  RECALCULO_SIMULACAO_REFIN_ITAU_SAGA = '@@atendimento/RECALCULO_SIMULACAO_REFIN_ITAU_SAGA',
  VERIFICA_NAO_PERTUBE_SAGA = '@@atendimento/VERIFICA_NAO_PERTUBE_SAGA',
  GET_INFORMACOES_LEAD_SAGA = '@@atendimento/GET_INFORMACOES_LEAD_SAGA',
  GET_INTEGRACAO_SAGA = '@@atendimento/GET_INTEGRACAO_SAGA',
  ENVIAR_SMS_SAGA = '@@atendimento/ENVIAR_SMS_SAGA',
  RESPOSTA_CLIENTE_SAGA = '@@atendimento/RESPOSTA_CLIENTE_SAGA'
}
export interface RecalculoPayload {
  cpf: string
  matricula: string
  quantidadeParcelaEmAberto: number
  valorTotalFinanciado: number
  valorParcela: number
  servico: string
  codigoTabela: string
  banco: string
  valorSaldoDevedor: number
}

export interface RecalculoSuccessData {
  message: string
  data: {
    valorParcela: number
    valorTotalFinanciado: number
    valorSaldoDevedor: number
    quantidadeParcelaEmAberto: number
  }
}

export interface RecalculoFailureData {
  message: string
}

export interface InformacoesAtendimento {
  cpf: string
  ativo: boolean
  tabAtendimento: number
  tabContato: TabContato
  tabProduto: TabProduto
  tabInformacoes: TabInformacoes
  tabHistorico: TabHistorico[]
  modalAtendimentoAgendado: boolean
}

export interface ModalCoberturasSeguroPrestamista {
  codigoPlano: number | null
  coberturas?:
    | {
        nomeCobertura: string
        valorBeneficio: number | null
      }[]
    | null
}

export interface ModalSeguroSindnapi {
  coberturasField?:
    | {
        nomeCoberturaField: string
        valorBeneficioField: number | null
      }[]
    | null
}

export interface ATENDIMENTO {
  atendimentosAbertos: InformacoesAtendimento[]
  cpfAtendimento: string
  tabClienteActive: number | string
  modalCadastroCliente: CadastroCliente
  invalidsCadastroCliente: InvalidsCadastroCliente
  isOpenConfirmacaoCadastro: boolean
  isOpenCadastroCliente: boolean
  convenios: Convenio[]
  perfis: Perfil[]
  formularioNecessidade: FormularioNecessidade
  loadingStartAtendimento: boolean
  modalAgregacao: {
    margemDisponivel: number
    simulacao?: ProdutosSimulacao | null
    valorParcelaOrigem: number
    valorASerAgregado: string
  }
  idsContratosRefinItauSelecionados: string[]
  modalCoberturasSeguroPrestamista: ModalCoberturasSeguroPrestamista
  modalSeguroSindnapi: ModalSeguroSindnapi
  tipoSaque: number
}

export interface CadastroCliente {
  nome: string
  cpf: string
  dataNascimento: string
  ddd: string
  telefone: string
  matricula: string
  perfilId: string | null
  convenioId?: string
  margem?: string
  margemCartao?: string
  indexTabCliente?: number
}

export interface InvalidsCadastroCliente {
  nome: boolean
  cpf: boolean
  dataNascimento: boolean
  ddd: boolean
  telefone: boolean
  matricula: boolean
  perfilId: boolean
  convenioId?: boolean
  margem?: boolean
  margemCartao?: boolean
}

export interface FormularioNecessidade {
  isOpen: boolean
  tabActive: number
  answers: AnswersFormularioNecessidade
}
