import { APICore } from 'helpers/api/apiCore'
import { APICoreWebSocket } from 'helpers/api/apiCoreWebSocket'
import {
  CadastroSimplificadoReq,
  FinalizaAgendamentoDto,
  InfoCliente,
  RequestValidacaoNovoAtendimentoDto,
  TabulacaoAtendimento
} from 'models/atendimento/types'
import { AnswersFormularioNecessidade } from 'models/types'
import { removeMaskCEP, removeMaskCPF, removeMaskMoney } from 'util/masks'

const api = new APICore()
const apiWs = new APICoreWebSocket()

export function getFormularioAtendimento30(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`Atendimento/valida-cliente?cpf=${cpfFormated}`, null)
}

export function salvarFormularioAtendimento30(
  params: AnswersFormularioNecessidade
) {
  const cpfFormated = removeMaskCPF(params.cpf)
  return api.create(`FormularioAtendimento/formulario-atendimento`, {
    valorDesejado: removeMaskMoney(params.valorDesejado),
    possuiCarro: params.possuiCarro,
    carroQuitado: params.carroQuitado,
    possuiImovel: params.possuiCarro,
    imovelQuitado: params.imovelQuitado,
    pagarDividas: params.pagarDividas,
    despesasMedicas: params.despesasMedicas,
    reformas: params.reformas,
    adquirirBem: params.adquirirBem,
    emprestarFamiliarAmigo: params.emprestarFamiliarAmigo,
    investirNegocio: params.investirNegocio,
    ferias: params.ferias,
    outros: params.outros,
    observacao: params.observacao,
    cpf: cpfFormated
  })
}

export function getInformacoesSimplificadas(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Atendimento/informacoes-simplificadas`, {
    cpf: cpfFormated
  })
}

/**Tab Contato*/
export function iniciarAtendimento(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Atendimento/iniciar-atendimento`, {
    cpf: cpfFormated
  })
}

export function getConvenios() {
  return api.get(`Generic/convenios`, null)
}

export function getPerfis() {
  return api.get(`Generic/perfis`, null)
}

export function getProfissoes() {
  return api.get(`Generic/profissoes`, null)
}

export function salvarCadastroSimplificado(params: CadastroSimplificadoReq) {
  return api.create(`Atendimento/cadastro-simplificado`, params)
}

export function getPerfilCliente(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Generic/matriculas-by-cpf`, {
    cpf: cpfFormated
  })
}

interface discarClienteProps {
  cpf: string
  tipoLigacao: number | string
  atendimentoId: string
  telefoneId?: string
}
export function discarCliente(params: discarClienteProps) {
  return api.create(
    `IntegracaoTelecom/discar?cpfCnpj=${params.cpf}&tipoLigacao=${
      params.tipoLigacao
    }&atendimentoId=${params.atendimentoId}&telefoneId=${
      params.telefoneId ?? ''
    }`,
    null
  )
}

export function getAtendimentos(
  cpf: string,
  pagina: number,
  registros: number
) {
  return api.get(`Atendimento/chamadas`, {
    cpf: cpf,
    pagina: pagina + 1,
    registros: registros
  })
}

export function getTabulacoes() {
  return api.get(`Tabulacao/tabulacoes`, null)
}

export function getTiposAtendimento() {
  return api.get(`Generic/tipos-atendimento`, null)
}

export function tabularAtendimento(params: TabulacaoAtendimento) {
  return api.create(`Atendimento/action-tabulacao`, params)
}

export function finalizaAgendamento(value: FinalizaAgendamentoDto) {
  return api.update(`Atendimento/finaliza-agendamento`, value)
}

export function enriquecerDados(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`Lemit?cpf=${cpfFormated}`, null)
}

export function validarNovoAtendimento(
  params: RequestValidacaoNovoAtendimentoDto
) {
  const dataFormatted: RequestValidacaoNovoAtendimentoDto = {
    ...params,
    cpf: removeMaskCPF(params.cpf)
  }
  return api.create(`Atendimento/valida-novo-atendimento`, dataFormatted)
}

export function getInformacoesLead(cpf: string, matricula: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Atendimento/informacoes-lead`, {
    cpf: cpfFormated,
    matricula
  })
}
export function enviarSms(phone: number, message: string) {
  return api.create(`Kolmeya/envia-sms`, [
    {
      phone,
      message
    }
  ])
}

export function respostaSms(telefone: number) {
  return api.get(`Kolmeya/reply-telefone`, {
    telefone
  })
}

export function adicionarNovoTelefone(
  cpf: string,
  telefone: string,
  isWhatsApp: boolean | null
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `Atendimento/adicionar-telefone?cpf=${cpfFormated}&ddd=${telefone.substring(
      1,
      3
    )}&numero=${telefone.substring(5)}&whatsapp=${isWhatsApp}`,
    null
  )
}

export function iniciarRetornoDiscagemTelecom(eventName: string) {
  return apiWs.connectToHub(`RetornoDiscagemTelecom`, eventName)
}

// /**Tab Produto*/

export function getPropostasCrm(cpf: string, numeroMatricula: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Operacao/simulacao`, {
    cpf: cpfFormated,
    numeroMatricula
  })
}
export function EditPropostas(
  Id: string,
  numeroProposta: string,
  valorParcela: number,
  parcelasEmAberto: number,
  saldoDevedor: number
) {
  return api.update(`Operacao/proposta-portabilidade`, {
    Id,
    numeroProposta,
    valorParcela,
    parcelasEmAberto,
    saldoDevedor
  })
}

export function solicitarSimulacaoFgts(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`fgts/solicitar-simulacao/${cpfFormated}`, {
    valorSolicitado: 0,
    quantidadeParcela: 10
  })
}

export function getParcelaFgts(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`fgts/parcela/${cpfFormated}`, {
    cpf: cpfFormated
  })
}

export function recalculoProposta(
  cpf: string,
  numeroMatricula: string,
  quantidadeparcelas: number,
  valorTotalFinanciado: number,
  valorParcela: number,
  servico: number,
  codigoProduto: number,
  banco: number,
  numeroProposta: string | null,
  taxaOrigem: number | null,
  quantidadeTotalParcelas: number,
  saldoDevedor: number | null,
  codigoBanco: string | null
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.getWithoutUndefined(`Operacao/recalculo-simulacao`, {
    cpf: cpfFormated,
    numeroMatricula,
    quantidadeparcelas,
    valorTotalFinanciado,
    valorParcela,
    servico,
    codigoProduto,
    banco,
    numeroProposta,
    taxaOrigem,
    quantidadeTotalParcelas,
    saldoDevedor,
    codigoBanco
  })
}

export function recalculoSimulacaoRefinItau(
  cpf: string,
  numeroMatricula: string,
  quantidadeParcelas: number,
  valorParcela: number,
  servico: number,
  codigoProduto: number,
  banco: number,
  contrato: string,
  valorAgregacao: number
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Operacao/recalculo-simulacao-refin-itau`, {
    cpf: cpfFormated,
    numeroMatricula,
    quantidadeParcelas,
    valorParcela,
    servico,
    codigoProduto,
    banco,
    contrato,
    valorAgregacao
  })
}

export function recalculoPropostaFgts(
  cpf: string,
  valor: number,
  parcelas: number
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`fgts/solicitar-simulacao/${cpfFormated}`, {
    cpf: cpfFormated,
    valorSolicitado: valor,
    quantidadeParcela: parcelas
  })
}

export function getResumoContratacao(cpf: string, matriculas: string[]) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`Atendimento/resumo-contratacao`, {
    cpf: cpfFormated,
    matriculas: matriculas
  })
}

export async function getSolicitacoesIN100(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Cliente/solicitacoesIn100`, {
    cpf: cpfFormated
  })
}

export function getTelefonesValidos(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Generic/telefones-validos`, {
    cpf: cpfFormated
  })
}

export function getTelefones(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Generic/telefones`, {
    cpf: cpfFormated
  })
}

export function getTelefonesMascara(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Generic/telefones-mascara`, {
    cpf: cpfFormated
  })
}

export function inserirSolicitacaoIN100(
  cpf: string,
  telefone: string,
  matricula: string
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `Operacao/inserir-solicitacao?cpf=${cpfFormated}&ddd=${telefone.substring(
      1,
      3
    )}&telefone=${telefone.substring(5)}&matricula=${matricula}`,
    null
  )
}

export function validaStatusIN100(cpf: string, matricula: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `Operacao/valida-status-in100?cpf=${cpfFormated}&numeroMatricula=${matricula}`,
    null
  )
}

export async function validacaoIN100(
  cpf: string,
  token: string,
  matricula: string
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `Operacao/valida-codigo-cliente?cpf=${cpfFormated}&matricula=${matricula}&token=${token}`,
    null
  )
}

export async function consentimentoItau(
  cpf: string,
  telefone: string,
  canal: string,
  matricula: string
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(
    `Operacao/coleta-consentimento?cpf=${cpfFormated}&ddd=${telefone.substring(
      1,
      3
    )}&numeroTelefone=${telefone
      .replace('-', '')
      .substring(5)}&numeroMatricula=${matricula}&canal=${canal}`,
    null
  )
}

export async function ConsultaConsentimentoItau(
  cpf: string,
  matricula: string
) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(
    `Operacao/consulta-consentimento?cpf=${cpfFormated}&numeroMatricula=${matricula}`,
    null
  )
}

export async function getProdutosContratados(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Operacao/propostas-pagas-cliente`, {
    cpf: cpfFormated
  })
}

export async function validacaoContratacao(cpf: string, matriculas: string[]) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`Operacao/validacao-contratacao`, {
    cpf: cpfFormated,
    matriculas
  })
}

interface FinalizacaoContratacaoCrmProps {
  cpf: string
  cpfAtendente?: string | null
  flagSaqueAutorizado?: boolean | null
  flagSaqueParcelado?: boolean | null
  flagAberturaConta?: boolean | null
  formaCredito?: number | null
  quantidadeParcelas: number
  valorParcela?: number
  finalidadeCredito?: number | null
  valorSaque?: number | null
  numeroProduto: number
  codigoSimulacao: number
  numeroMatricula: string
  servico: number
  bancoSimulacaoEnum: number
  numeroContaInterna?: number | null
  flagRefin?: boolean
  codigoPlano?: number | null
  descricaoSeguro?: string
  capitalSegurado?: number | null
  valorDoSeguro?: number
  tipoSaque: number
  valorLiberado: number
  codigoTabelaFator?: string | null
  numeroContrato?: string | null
  saldoDevedor?: number | null
  quantidadeParcelasAberto?: number | null
  quantidadeParcelasOriginal?: number | null
  numeroProdutoRefin?: number | null
  codigoBanco?: string | null
}

export async function finalizacaoContratacaoCrm({
  cpf,
  finalidadeCredito,
  flagSaqueAutorizado,
  flagSaqueParcelado,
  formaCredito,
  quantidadeParcelas,
  valorParcela,
  servico,
  valorSaque,
  numeroProduto,
  codigoSimulacao,
  numeroMatricula,
  flagAberturaConta,
  bancoSimulacaoEnum,
  numeroContaInterna,
  flagRefin,
  codigoPlano,
  descricaoSeguro,
  capitalSegurado,
  valorDoSeguro,
  tipoSaque,
  valorLiberado,
  codigoTabelaFator,
  numeroContrato,
  saldoDevedor,
  quantidadeParcelasAberto,
  quantidadeParcelasOriginal,
  numeroProdutoRefin,
  codigoBanco
}: FinalizacaoContratacaoCrmProps) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`Operacao/finalizacao-contratacao`, {
    cpf: cpfFormated,
    finalidadeCredito,
    flagSaqueAutorizado,
    flagSaqueParcelado,
    formaCredito,
    quantidadeParcelas,
    valorParcela,
    servico,
    valorSaque,
    numeroProduto,
    numeroMatricula,
    codigoSimulacao,
    flagAberturaConta,
    bancoSimulacaoEnum,
    numeroContaInterna,
    flagRefin,
    codigoPlano,
    descricaoSeguro,
    capitalSegurado,
    valorDoSeguro,
    tipoSaque,
    valorLiberado,
    codigoTabelaFator,
    numeroContrato,
    saldoDevedor,
    quantidadeParcelasAberto,
    quantidadeParcelasOriginal,
    numeroProdutoRefin,
    codigoBanco
  })
}

export async function finalizacaoContratacaoFgts(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.create(`fgts/solicitar-proposta/${cpfFormated}`, {
    cpf: cpfFormated
  })
}

// /**Tab Informações*/

export function getInformacoesCliente(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Atendimento/informacoes-cliente`, {
    cpf: cpfFormated
  })
}

export function getGeneros() {
  return api.get(`Generic/generos`, null)
}

export function getOrgaosEmissores() {
  return api.get(`Generic/orgaos-emissores`, null)
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getEscolaridades() {
  return api.get(`Generic/escolaridades`, null)
}

export function getEstadosCivis() {
  return api.get(`Generic/estados-civis`, null)
}

export function getTiposConta() {
  return api.get(`Generic/tipos-conta`, null)
}

export function getBancos() {
  return api.get(`Generic/bancos-dados-bancarios`, null)
}

export function getCidades(siglaEstado?: string) {
  return api.get(`Generic/cidades`, {
    siglaEstado: siglaEstado
  })
}

export function salvarInformacoesCliente(infosCliente: InfoCliente) {
  return api.update(`Atendimento/informacoes-cliente`, infosCliente)
}

export function getDadosEndereco(cep: string) {
  const cepFormated = removeMaskCEP(cep)
  return api.get(`Generic/informacoes-endereco`, { cep: cepFormated })
}

// /**Tab Histórico*/

export function getContratos(
  cpf: string,
  pagina: number,
  registros: number,
  numeroMatricula: string
) {
  return api.get(`Atendimento/contratos`, {
    cpf: cpf,
    pagina: pagina + 1,
    registros,
    numeroMatricula
  })
}

export function getInfosClienteConsig(cpf: string, numeroMatricula: string) {
  return api.get(`Atendimento/big-numbers`, {
    cpf: cpf,
    numeroMatricula
  })
}

export function verificaNaopertube(cpf: string) {
  const cpfFormated = removeMaskCPF(cpf)
  return api.get(`Atendimento/nao-pertube`, {
    cpf: cpfFormated
  })
}

export function getIntegracao() {
  return api.get(`ParametroIntegracao`, null)
}
