import React, { useEffect } from 'react'
import SimpleCard from 'components/SimpleCard'
import { FiServer } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import InputCustom from 'components/Inputs'
import { Switch } from '@mui/material'
import {
  cleanRegister,
  setAtiveTab,
  setRegisterFields
} from 'store/modules/backoffice/tabulacao/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { TABULACAO } from 'store/modules/backoffice/tabulacao/types'
import CustomSelect from 'components/Select'
import useRedux from 'hooks/useRedux'
import * as tabulacaoActions from 'store/modules/backoffice/tabulacao/actions'
import * as yup from 'yup'
import { TabulacaoReq } from 'models/backoffice/tabulacao/type'
import { toast } from 'react-toastify'
import InputVariable from '../../../../../components/Inputs/InputVariable'

const Register = () => {
  const {
    register,
    polaridades,
    localizacoes,
    invalids,
    agendamentos,
    visualizacao
  } = useSelector<ApplicationState, TABULACAO>((state) => state.tabulacao)

  const { dispatch } = useRedux()

  function voltaParaSearch() {
    dispatch(cleanRegister())
    dispatch(tabulacaoActions.cleanInvalids())
    dispatch(tabulacaoActions.setVisualizacao(false))
    dispatch(setAtiveTab('search'))
  }

  function onChange(value: string | number | boolean, field: string) {
    dispatch(setRegisterFields(value, field))
  }

  function onSave() {
    dispatch(tabulacaoActions.cleanInvalids())
    schema
      .validate(
        {
          id: register.id,
          descricao: register.descricao,
          retirarClienteCampanha: register.retirarClienteCampanha,
          condenarTelefone: register.condenarTelefone,
          pularParaProximoTelefone: register.pularParaProximoTelefone,
          finalizarAtendimento: register.finalizarAtendimento,
          polaridadeId: register.polaridadeId,
          localizacaoId: register.localizacaoId,
          periodoAgendamentoId: register.periodoAgendamentoId,
          observacoes: register.observacoes,
          mensagemSms: register.mensagemSms,
          enviarSms: register.enviarSms
        },
        { abortEarly: false }
      )
      .then(() => {
        if (!register.id) {
          dispatch(tabulacaoActions.criarTabulacaoSaga())
        } else {
          dispatch(tabulacaoActions.atualizarTabulacaoSaga())
        }
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(tabulacaoActions.setInvalids(true, e.path || ''))
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  useEffect(() => {
    dispatch(tabulacaoActions.getPolaridadesSaga())
    dispatch(tabulacaoActions.getLocalizacoesSaga())
    dispatch(tabulacaoActions.getAgendamentosSaga())
  }, [dispatch])

  const schema: yup.SchemaOf<TabulacaoReq> = yup.object().shape({
    id: yup.string(),
    descricao: yup.string().required().min(3).max(100),
    retirarClienteCampanha: yup.boolean().required(),
    condenarTelefone: yup.boolean().required(),
    pularParaProximoTelefone: yup.boolean().required(),
    finalizarAtendimento: yup.boolean().required(),
    polaridadeId: yup.string().required(),
    localizacaoId: yup.string().required(),
    periodoAgendamentoId: yup.string().nullable(),
    observacoes: yup.string().nullable(),
    mensagemSms: yup
      .string()
      .when('enviarSms', {
        is: true,
        then: yup.string().required().min(3).max(100)
      })
      .nullable(),
    enviarSms: yup.boolean().required()
  })

  return (
    <SimpleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <Row className="mb-2">
          <Col md={6} className="d-flex">
            <CustomButton
              background="#662d90"
              onClick={() => {
                voltaParaSearch()
              }}
              letterSpacing={1}
            >
              VOLTAR
            </CustomButton>
          </Col>
          {!visualizacao && (
            <Col md={6} className="d-flex justify-content-end">
              <CustomButton onClick={() => onSave()} letterSpacing={1}>
                SALVAR
              </CustomButton>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <SimpleTitleCard>
              <FiServer className="icon" />
              Cadastro de tabulação
            </SimpleTitleCard>
          </Col>
        </Row>
        <Row className="d-flex row-gap-10">
          <Col md={6}>
            <label className="label-12">Descrição*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'descricao')
              }}
              value={register.descricao}
              invalid={invalids.descricao}
              disabled={visualizacao}
            />
          </Col>
        </Row>
        <Row className="d-flex row-gap-10">
          <Col className="d-flex flex-column" md={2}>
            <label className="label-12">Retirar cliente da campanha</label>
            <Switch
              onChange={(e) => {
                onChange(e.target.checked, 'retirarClienteCampanha')
              }}
              checked={register.retirarClienteCampanha}
              disabled={visualizacao}
            />
          </Col>
          <Col className="d-flex flex-column" md={2}>
            <label className="label-12">Condenar telefone</label>
            <Switch
              onChange={(e) => {
                onChange(e.target.checked, 'condenarTelefone')
              }}
              checked={register.condenarTelefone}
              disabled={visualizacao}
            />
          </Col>
          <Col className="d-flex flex-column" md={2}>
            <label className="label-12">Pular para o próximo telefone</label>
            <Switch
              onChange={(e) => {
                onChange(e.target.checked, 'pularParaProximoTelefone')
              }}
              checked={register.pularParaProximoTelefone}
              disabled={visualizacao}
            />
          </Col>
          <Col className="d-flex flex-column" md={2}>
            <label className="label-12">Finalizar atendimento</label>
            <Switch
              onChange={(e) => {
                onChange(e.target.checked, 'finalizarAtendimento')
              }}
              checked={register.finalizarAtendimento}
              disabled={visualizacao}
            />
          </Col>
          <Col className="d-flex flex-column" md={2}>
            <label className="label-12">Enviar SMS</label>
            <Switch
              onChange={(e) => {
                onChange(e.target.checked, 'enviarSms')
              }}
              checked={register.enviarSms}
              disabled={visualizacao}
            />
          </Col>
        </Row>
        <div className="linha-horizontal"></div>
        <Row className="d-flex row-gap-10">
          <Col md={6}>
            <label className="label-12">Polaridade*</label>
            <CustomSelect
              options={polaridades}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'polaridadeId')
              }}
              value={register.polaridadeId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalids.polaridadeId}
              disabled={visualizacao}
            />
          </Col>
          <Col md={6}>
            <label className="label-12">Localização*</label>
            <CustomSelect
              options={localizacoes}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'localizacaoId')
              }}
              value={register.localizacaoId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalids.localizacaoId}
              disabled={visualizacao}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Agendamento</label>
            <CustomSelect
              options={agendamentos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'periodoAgendamentoId')
              }}
              value={register.periodoAgendamentoId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalids.periodoAgendamentoId}
              disabled={visualizacao}
            />
          </Col>
        </Row>
        <div className="linha-horizontal"></div>
        <Row className="d-flex row-gap-10">
          <Col md={6}>
            <label className="label-12">Observações</label>
            <InputCustom
              type="textarea"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'observacoes')
              }}
              value={register.observacoes || ''}
              invalid={invalids.observacoes}
              disabled={visualizacao}
            />
          </Col>
          {register.enviarSms && (
            <Col md={6}>
              <label className="label-12">Mensagem SMS</label>
              <InputVariable
                rawValue={register.mensagemSms || ''}
                onRawChange={(value) => {
                  onChange(value, 'mensagemSms')
                }}
                placeholder="Digite aqui..."
                variables={[
                  { descricao: 'Nome_Cliente', campoEquivalente: 'nome' },
                  {
                    descricao: 'Valor_da_Oportunidade',
                    campoEquivalente: 'valor'
                  }
                ]}
              />
              <small>{register.mensagemSms?.length || 0}/150 caracteres</small>
            </Col>
          )}
        </Row>
      </div>
    </SimpleCard>
  )
}

export default Register
