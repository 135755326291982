import { useEffect } from 'react'
import { Modal, ModalHeader, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import * as atendimentosActions from 'store/modules/atendimento/actions'
import {
  ClientReplyBubble,
  CloseButton,
  SendButton,
  StyledModalBody,
  UserMessageBubble
} from 'views/atendimento/style'
import InputVariable from 'components/Inputs/InputVariable'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import useRedux from 'hooks/useRedux'

interface VisualizarSMSModalProps {
  isOpen: boolean
  toggle: () => void
  index: number
}

const VisualizarSMSModal = ({
  isOpen,
  toggle,
  index
}: VisualizarSMSModalProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )
  const { dispatch } = useRedux()

  useEffect(() => {
    if (isOpen) {
      dispatch(
        atendimentosActions.respostaClienteSaga(
          atendimentosAbertos[index].tabContato.modalSms.telefone,
          index
        )
      )
    } else {
      dispatch(
        atendimentosActions.setInfoContatoModalSmsField('', 'mensagem', index)
      )
      dispatch(
        atendimentosActions.setInfoContatoModalSmsField([], 'resposta', index)
      )
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    isOpen,
    dispatch,
    atendimentosAbertos[index].tabContato.modalSms.telefone,
    index
  ])
  /* eslint-enable react-hooks/exhaustive-deps */
  const handleSendSms = () => {
    dispatch(atendimentosActions.enviarSmsSaga(index))
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Visualizar e Responder SMS</ModalHeader>

      <StyledModalBody>
        {(atendimentosAbertos[index]?.tabContato?.modalSms?.resposta || [])
          .length > 0 ? (
          (
            atendimentosAbertos[index]?.tabContato?.modalSms?.resposta || []
          ).map((reply, i) => (
            <ClientReplyBubble key={i}>
              <p>{reply}</p>
            </ClientReplyBubble>
          ))
        ) : (
          <ClientReplyBubble>
            <p>O cliente ainda não respondeu.</p>
          </ClientReplyBubble>
        )}

        <UserMessageBubble>
          <InputVariable
            rawValue={atendimentosAbertos[index].tabContato.modalSms.mensagem}
            onRawChange={(value) =>
              dispatch(
                atendimentosActions.setInfoContatoModalSmsField(
                  value,
                  'mensagem',
                  index
                )
              )
            }
            placeholder="Digite aqui..."
            variables={[
              { descricao: 'Nome_Cliente', campoEquivalente: 'nome' },
              { descricao: 'Valor_da_Oportunidade', campoEquivalente: 'valor' }
            ]}
          />
        </UserMessageBubble>
      </StyledModalBody>
      <div className="container-fluid">
        <Row className="p-3">
          <Col className="d-flex justify-content-between">
            <CloseButton onClick={toggle}>Fechar</CloseButton>
            <SendButton onClick={handleSendSms}>Enviar</SendButton>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default VisualizarSMSModal
