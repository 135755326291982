import { maskDate, maskMoney, removeMaskPhone } from 'util/masks'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridColumns
} from '@mui/x-data-grid'
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid'
import { EnumServicos } from 'models/enums'
import { Tooltip } from '@mui/material'
import { Row } from 'reactstrap'
import { FaRegEye } from 'react-icons/fa'
import {
  respostaClienteSaga,
  setInfoContatoModalSmsFieldDispatch
} from 'store/modules/atendimento/actions'

const headerTheme = 'table-header--lines'
const cellTheme = 'table-cell--lines'

export const COLUMNS_ATENDIMENTO: GridColumns<Row> = [
  {
    field: 'descricaoTabulacao',
    headerName: 'Tabulação',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.descricaoTabulacao || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'canal',
    headerName: 'Canal',
    valueGetter: (params: GridValueGetterParams) => `${params.row.canal || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'dataChamada',
    headerName: 'Data',
    sortable: true,
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY HH:mm') : ''}
      </span>
    ),
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'nomeAtendente',
    headerName: 'Atendente',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.nomeAtendente || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'ddd',
    headerName: 'DDD',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => `${params.row.ddd || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'telefone',
    headerName: 'Número',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.telefone || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Ações',
    headerClassName: headerTheme,
    type: 'actions',
    align: 'center',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={params.row.id}
        icon={<FaRegEye />}
        label="Visualizar"
        onClick={() => {
          setInfoContatoModalSmsFieldDispatch(true, 'isOpen', params.row.index)
          setInfoContatoModalSmsFieldDispatch(
            Number(removeMaskPhone(params.row.ddd + params.row.telefone)),
            'telefone',
            params.row.index
          )
          respostaClienteSaga(
            Number(removeMaskPhone(params.row.ddd + params.row.telefone)),
            params.row.index
          )
        }}
      />
    ]
  }
]

export const COLUMNS_HISTORICO: GridColDef[] = [
  {
    field: 'codigoBanco',
    headerName: 'Código do Banco',
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'banco',
    headerName: 'Banco',
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'numeroContrato',
    headerName: 'Contrato',
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'tipoEmprestimo',
    headerName: 'Tipo Empréstimo',
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'valorContrato',
    headerName: 'Valor do contrato',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(params.row.valorContrato?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'contrato',
    headerName: 'Contrato',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.inicioContrato || ''}-${params.row.fimContrato || ''}`,
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'parcelas',
    headerName: 'Parcelas',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.parcelasEmAberto || ''}/${
        params.row.quantidadeParcelas || ''
      }`,
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'valorParcela',
    headerName: 'Valor da Parcela',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(params.row.valorParcela?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'dataAverbacao',
    headerName: 'Data Averbação',
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY') : ''}
      </span>
    ),
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'situacao',
    headerName: 'Situação',
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'taxa',
    headerName: 'Taxa',
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  },
  {
    field: 'saldoDevedorAproximado',
    headerName: 'Saldo Devedor Aprox.',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(params.row.saldoDevedorAproximado?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    cellClassName: cellTheme,
    align: 'left',
    headerAlign: 'left',
    width: 55,
    flex: 1
  }
]

export const COLUMNS_RESUMO_PRODUTOS: GridColDef[] = [
  {
    field: 'servico',
    headerName: 'Produto',
    valueGetter: (params: GridValueGetterParams) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      `${EnumServicos[params.row.servico] || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'banco',
    headerName: 'Banco',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'matricula',
    headerName: 'Benefício',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'valorTotalFinanciado',
    headerName: 'Total',
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.servico === 5 ||
        params.row.servico === 6 ||
        params.row.servico === 7 ||
        params.row.servico === 8 ||
        params.row.servico === 12
          ? params.row.flagSaqueAutorizado || params.row.flagSaqueParcelado
            ? params.row.valorSaque
            : '-'
          : maskMoney(params.row.valorTotalFinanciado?.toFixed(2)) || ''
      }`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'quantidadeParcelas',
    headerName: 'Parcelas',
    headerClassName: headerTheme,
    valueGetter: (params: GridValueGetterParams) =>
      `${
        (params.row.servico === 5 && params.row.quantidadeParcelas == null) ||
        (params.row.servico === 6 && !params.row.flagSaqueParcelado) ||
        (params.row.servico === 7 && !params.row.flagSaqueParcelado) ||
        params.row.servico === 8 ||
        params.row.servico === 12
          ? '-'
          : params.row.quantidadeParcelas
      }`,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'valorParcela',
    headerName: 'Valor Parcelas',
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.servico === 12 ||
        (params.row.servico === 6 && !params.row.flagSaqueParcelado) ||
        (params.row.servico === 7 && !params.row.flagSaqueParcelado)
          ? maskMoney(
              params.row.produtosSINDNAP?.planos[0].valorPremioAux?.toFixed(2)
            ) || '-'
          : maskMoney(params.row.valorParcela?.toFixed(2)) || '-'
      }`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'valorPremio',
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.servico === 5 ||
        params.row.servico === 6 ||
        params.row.servico === 7 ||
        params.row.servico === 8
          ? params.row.valorPremio === undefined || params.row.valorPremio === 0
            ? '-'
            : maskMoney(params.row.valorPremio.toFixed(2))
          : '-'
      }`,
    headerName: 'Valor Seguro',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]

export const COLUMNS_DETALHAMENTO_PARCELAS_FGTS: GridColDef[] = [
  {
    field: 'numeroParcela',
    headerName: 'Parcelas',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'parcelaLiberada',
    headerName: 'Valor',
    valueGetter: (params: GridValueGetterParams) => {
      return `${
        maskMoney(Number(params.row.parcelaLiberada)?.toFixed(2)) || ''
      }`
    },
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'dataVencimento',
    headerName: 'Data desconto parcela',
    type: 'datetime',
    renderCell: (params: GridRenderCellParams) => (
      <span className="table-cell-trucate">
        {params?.value ? maskDate(params?.value, 'DD/MM/YYYY') : ''}
      </span>
    ),
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'parcelaOriginal',
    headerName: 'Parcela original',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(Number(params.row.parcelaOriginal)?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'iof',
    headerName: 'I.O.F',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(Number(params.row.iof)?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]

export const COLUMNS_COBERTURAS: GridColumns<Row> = [
  {
    field: 'nomeCobertura',
    headerName: 'Nome Cobertura',
    headerClassName: headerTheme,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    )
  },
  {
    field: 'valorBeneficio',
    headerName: 'Valor Benefício',
    headerClassName: headerTheme,
    headerAlign: 'right',
    align: 'right',
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    )
  }
]

export const COLUMNS_COBERTURA_SINDNAPI: GridColumns<Row> = [
  {
    field: 'nomeCoberturaField',
    headerName: 'Nome Cobertura',
    headerClassName: headerTheme,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    )
  },
  {
    field: 'valorBeneficio',
    headerName: 'Valor Benefício',
    headerClassName: headerTheme,
    headerAlign: 'right',
    align: 'right',
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.value || ''}>
        <span className="table-cell-trucate">{params?.value}</span>
      </Tooltip>
    )
  }
]
