import styled from 'styled-components'
import { Input as BootstrapInput } from 'reactstrap'

export const InputArea = styled.div`
  & .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none !important;
  }

  & input,
  & textarea {
    border-radius: 15px;
    width: 100%;
  }

  & input[type='text'] {
  }
  & input[type='date'] {
  }
  & input[type='time'] {
  }
  & input[type='checkbox'].checkbox-roxo {
    align-items: center;
    background: transparent;
    border: var(--roxo-riber) solid 2px;
    border-radius: 5px;
    content: '';
    display: flex;
    height: 25px;
    justify-content: center;
    margin: 0;
    width: 25px;
    &:checked:after {
      align-items: center;
      background-color: var(--roxo-riber);
      border-radius: 2px;
      content: '';
      display: flex;
      height: 15px;
      justify-content: center;
      width: 15px;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledTextarea = styled(BootstrapInput).attrs({
  type: 'textarea'
})<{ isInvalid: boolean }>`
  padding: 12px;
  height: 80px;
  max-height: 150px;
  line-height: 1.5;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  background-color: #f9f9f9; /* Fundo mais claro */
  border: ${({ isInvalid }) =>
    isInvalid ? '1px solid #ff4d4f' : '1px solid #d9d9d9'};
  border-radius: 8px;
  color: #333;
  font-size: 16px;

  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::placeholder {
    color: #a0a0a0;
  }

  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
  }
`
export const StyledInput = styled(BootstrapInput)<{ isInvalid: boolean }>`
  padding: 10px;
  height: 80px;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  border: ${({ isInvalid }) =>
    isInvalid ? '1px solid red' : '1px solid #ced4da'};
  border-radius: 4px;
  background-color: white;

  white-space: normal;
  word-break: break-word;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::placeholder {
    color: #6c757d;
  }

  &:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`

export const Dropdown = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  max-width: 300px;
  right: 0;
  padding: 20px;
`

export const IconButtonWrapper = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const SearchInput = styled(BootstrapInput)`
  margin-bottom: 16px;
`

export const VariableItem = styled.div<{ isLast: boolean }>`
  display: flex;
  gap: 10px;
  cursor: pointer;
  justify-content: start;
  ${({ isLast }) => !isLast && 'margin-bottom: 12px;'}
`

export const HighlightedText = styled.span`
  color: #007bff;
`
